<template>
  <div class="room">
    <Header :info="states.room" :actions="actions" />
    <div v-if="states.room" class="room-content">
      <div v-html="states.room.information" class="desc"></div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import { reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import api from '@/api/api'

export default {
  name: 'Room',
  components: { Header },
  data() {
    return {
      actions: [
        {
          actionName: '住宿訂房',
          action: () => {
            window.openUrlExternal(this.states.room.bookingUrl)
          },
        },
      ],
    }
  },
  setup() {
    const store = useStore()
    const states = reactive({
      room: store.state.selectedRoom,
    })
    const route = useRoute()
    const router = useRouter()
    if (!states.room) {
      api
        .RoomList({
          hotelId: route.params.sid,
        })
        .then(result => {
          states.room = result.data.rooms[route.params.rid]
        })
        .catch(err => {
          console.error(err)
          router.replace({ name: 'Store', params: { sid: route.params.sid } })
        })
    }

    return { states }
  },
  created() {
    localStorage.setItem('activeTab', 0)
  },
}
</script>

<style lang="scss" scoped>
.room-content {
  padding: 0px 5.33% 99px;
}
</style>
